/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';

import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

const FourUpVariantA = loadable(() => import(/* webpackChunkName: "FourUpVariantA" */ './FourUpVariantA/FourUpVariantA'));
const FourUpVariantB = loadable(() => import(/* webpackChunkName: "FourUpVariantB" */ './FourUpVariantB/FourUpVariantB'));
const FourUpVariantC = loadable(() => import(/* webpackChunkName: "FourUpVariantC" */ './FourUpVariantC/FourUpVariantC'));

const FourUp = ({
    data,
}) => {
    if (data.length) {
        const fourUpData = data[0] || {};
        const gaConstants = fourUpData.ga_constants;

        return (
            <div data-testid="fourUp">
                {fourUpData.variant_a.length > 0 && <FourUpVariantA data={fourUpData.variant_a[0]} gaConstants={gaConstants} />}
                {fourUpData.variant_b.length > 0 && <FourUpVariantB data={fourUpData.variant_b[0]} gaConstants={gaConstants} />}
                {fourUpData.variant_c.length > 0 && <FourUpVariantC data={fourUpData.variant_c[0]} gaConstants={gaConstants} />}
            </div>
        );
    }
    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Tiles/Four/Four.js',
        message: 'No data available to render component',
    });
};

FourUp.propTypes = {
    data: array.isRequired,
};

export default FourUp;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';

import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

const ThreeUpSimple = loadable(() => import(/* webpackChunkName: "ThreeUpSimple" */ './ThreeUpSimple/ThreeUpSimple'));
const ThreeUpFull = loadable(() => import(/* webpackChunkName: "ThreeUpFull" */ './ThreeUpFull/ThreeUpFull'));
const ThreeUpHalf = loadable(() => import(/* webpackChunkName: "ThreeUpFull" */ './ThreeUpHalf/ThreeUpHalf'));
const ThreeUpColor = loadable(() => import(/* webpackChunkName: "ThreeUpColor" */ './ThreeUpColor/ThreeUpColor'));

const ThreeUp = ({
    data,
}) => {
    if (data.length) {
        const threeUpData = data[0] || {};
        const gaConstants = threeUpData.ga_constants;
        return (
            <div data-testid="3up">
                {threeUpData.simple.length > 0 && <ThreeUpSimple data={threeUpData.simple[0]} gaConstants={gaConstants} />}
                {threeUpData.full.length > 0 && <ThreeUpFull data={threeUpData.full[0]} gaConstants={gaConstants} />}
                {threeUpData.color.length > 0 && <ThreeUpColor data={threeUpData.color[0]} gaConstants={gaConstants} />}
                {threeUpData.half.length > 0 && <ThreeUpHalf data={threeUpData.half[0]} gaConstants={gaConstants} />}

            </div>
        );
    }
    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Tiles/ThreeUp/ThreeUp.js',
        message: 'No data available to render component',
    });
};

ThreeUp.propTypes = {
    data: array.isRequired,
};

export default ThreeUp;

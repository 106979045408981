/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
// Loadable Banner Types
import useStyles from 'isomorphic-style-loader/useStyles';
import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import style from './Tiles.module.css';

import TallBanner from './TwoUp/Standard/Tall/Tall'; // Rename to TwoUp, this is not a banner
import ThreeUp from './ThreeUp/ThreeUp'; // Do not use numbers
import FourUp from './FourUp/FourUp';
import FourUpStandard from './FourUpStandard/FourUpStandard';
import FiveUp from './FiveUp/FiveUp';
import SixUp from './SixUp/SixUp';
import EightUp from './EightUp/EightUp';
import BlockHeading from '../../../../ContentTypeComponents/BlockHeading';

// helpers
import cmsCssClassesToString from '../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';

const Tiles = ({
    data, salesforceResponse,
}) => {
    useStyles(style);
    const heading = data?.tiles?.reference?.[0]?.content?.heading?.text || data?.tiles?.reference?.[0]?.tiles_5up_standard_headingcta?.[0]?.content?.heading?.text;
    const cssClasses = cmsCssClassesToString(data?.tiles?.reference?.[0]?.content?.heading?.css_classes || data?.tiles?.reference?.[0]?.tiles_5up_standard_headingcta?.[0]?.content?.heading?.css_classes) || 'header3';

    const getTiles = () =>  {
        const contentTypeUid =  data?.tiles?.reference?.[0]?.['_content_type_uid'];
        switch (contentTypeUid) {
            case 'tiles_2up_standard':
                return (
                    <ComponentErrorBoundary module="Banner.BannerTall">
                        <TallBanner data={data.tiles.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_3up_standard':
                return (
                    <ComponentErrorBoundary module="3up.ThreeUp">
                        <ThreeUp data={data.tiles.reference} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_4up_standard_tall':
                return (
                    <ComponentErrorBoundary module="Tiles.fourUp">
                        <FourUp data={data.tiles.reference} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_four_up': // THIS IS THE WRONG UID FORMAT. LEAVING FOR LEGACY ENTRIES. WILL NEED TO CLEAN UP LATER
                return (
                    <ComponentErrorBoundary module="Tiles.fourUpStandard">
                        <FourUpStandard data={data.tiles.reference} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_4up': // THIS IS THE PROPER FORMAT. REMOVE THIS COMMENT WHEN OLD ONE IS REMOVED
                return (
                    <ComponentErrorBoundary module="Tiles.fourUpStandard">
                        <FourUpStandard data={data.tiles.reference} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_5up_standard':
                return (
                    <ComponentErrorBoundary module="Tiles.FiveUp">
                        <FiveUp data={data} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_6up_standard':
                return (
                    <ComponentErrorBoundary module="Tiles.SixUpTiles">
                        <SixUp data={data.tiles.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'tiles_8up_standard':
                return (
                    <ComponentErrorBoundary module="Tiles.EightUpTiles">
                        <EightUp data={data} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            default: return null;
        }
    };

    const getHeading = () => {
        if (data?.tiles?.heading) return <div className={style.headingContainer}><BlockHeading heading={data.tiles.heading} /></div>;
        if (heading) return <h3 class={cssClasses}>{heading}</h3>;
        return <></>;
    };

    return (
        <div data-testid="tiles" className={style.container}>
            {getHeading()}
            {getTiles()}
        </div>
    );
};

Tiles.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
Tiles.defaultProps = {
    salesforceResponse: {},
};

export default Tiles;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';

import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';

import BannerMediumStandard from './OneUp/Standard/Medium/Medium';
import Banner1UpExtraTallStandard from './OneUp/Standard/ExtraTall/ExtraTall';
import Banner1UpTallStandard from './OneUp/Standard/Tall/Tall';
import BannerExtraShortStandard from './OneUp/Standard/ExtraShort/ExtraShort';
import BannerShortStandard from './OneUp/Standard/Short/Short';
import Banner1UpVideoTallStandard from './OneUp/Video/Tall/Tall';

const Banners = ({
    data, salesforceResponse,
}) => {
    const getBanner = () =>  {
        const contentTypeUid =  data?.banner?.reference?.[0]?.['_content_type_uid'];
        switch (contentTypeUid) {
            case 'banner_1up_standard_extratall':
                return (
                    <ComponentErrorBoundary module="Banner.BannerExtraTall">
                        <Banner1UpExtraTallStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'banner_1up_standard_tall':
                return (
                    <ComponentErrorBoundary module="Banner.BannerTall">
                        <Banner1UpTallStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'banner_1up_standard_medium':
                return (
                    <ComponentErrorBoundary module="Banner.BannerMedium">
                        <BannerMediumStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'banner_1up_standard_short':
                return (
                    <ComponentErrorBoundary module="Banner.BannerShort">
                        <BannerShortStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'banner_1up_standard_extrashort':
                return (
                    <ComponentErrorBoundary module="Banner.BannerExtraShort">
                        <BannerExtraShortStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            case 'banner_1up_video_tall':
                return (
                    <ComponentErrorBoundary module="Banner.BannerTallVideo">
                        <Banner1UpVideoTallStandard data={data.banner.reference[0]} salesforceResponse={salesforceResponse} />
                    </ComponentErrorBoundary>
                );
            default: return null;
        }
    };

    return (
        <div data-testid="banners">
            {getBanner()}
        </div>
    );
};

Banners.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};

Banners.defaultProps = {
    salesforceResponse: {},
};

export default Banners;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './Tall.module.css';

import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const LinkListBanner = loadable(() => import(/* webpackChunkName: "LinkListBanner" */ '../../../../../SubComponents/LinkListBanner/LinkListBanner'));
const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const TallBanner = ({ data, salesforceResponse  }) =>     {
    useStyles(styles);
    if (!Object.keys(data || {})?.length) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Standard/Tall/Tall.js',
            message: 'No data available to render component',
        });
        return <></>;
    }
    const isBackground =  data?.message?.has_background;
    const linkListPosition = data?.link_list?.[0]?.link_list?.position || 'row';
    const backgrounds = {
        desktop: data.background_image?.desktop?.url,
        tablet: data.background_image.tablet?.url || data.background_image?.desktop?.url, // can add fallbacks easily this way
        mobile: data.background_image.mobile?.url || data.background_image.tablet?.url || data.background_image?.desktop?.url,
    };
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string

    const renderLinkList = (matches, deviceArray) => {
        if (data.has_link_list && deviceArray.includes(device(matches))) return <LinkListBanner data={data.link_list[0]} />;
        return <></>;
    };
    const handleClick = universalHandleClick(salesforceResponse);
    const imageQuality = '?auto=webp';
    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgrounds[device(matches)]}${imageQuality})`, flexDirection: linkListPosition }} className={`${styles.banner} ${data?.has_link_list ? styles.wrapperContainer : ''}`}>
                {Object.keys(data.message)?.length > 0 && (data?.link_list?.length > 0 && (isBackground || !isBackground)) && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><MessageStandard data={data.message} /></div>}
                {!isBackground && !data?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><MessageStandard data={data.message} /></div>}
                {(device(matches) === 'desktop' || device(matches) === 'tablet') && isBackground && !data?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><MessageStandard data={data.message} /></div>}
                {renderLinkList(matches, ['desktop'])}
            </div>
            {device(matches) === 'mobile' && isBackground && !data?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><MessageStandard data={data.message} /></div>}
            {renderLinkList(matches, ['tablet', 'mobile'])}
            {backgrounds[device(matches)] && (
                <picture>
                    <source srcSet={`${backgrounds[device(matches)]}${imageQuality}`} />
                    <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" className={styles.lcpImage} loading="lazy" />
                </picture>
            )}
        </>
    );
    const bannerLink = data?.message?.cta?.[0]?.link;
    return (
        <div data-testid="tallBanner" className={`${bannerLink?.href && bannerLink?.title ? styles.bannerContainer : ''} ${isBackground && data?.has_link_list ? styles.container : ''}`} role="presentation" onClick={() => handleClick(data.message.cta[0])}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};

TallBanner.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
TallBanner.defaultProps = {
    salesforceResponse: {},
};

export default TallBanner;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    func,
} from 'prop-types';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';

const ResponsiveWrapper = ({ renderer }) =>     {
    const ssrDeviceType = useSelector(getSSRDeviceType);
    return (
        <Media
            queries={{
                desktop: '(min-width:1024px)',
                tablet: '(min-width: 600.5px) and (max-width: 1023.5px)',
                mobile: '(max-width: 600px)',
            }}
            defaultMatches={{
                desktop: ssrDeviceType === 'desktop',
                tablet: ssrDeviceType === 'tablet',
                mobile: ssrDeviceType === 'mobile',
            }}
        >
            {(matches) => renderer(matches)}
        </Media>
    );
};
ResponsiveWrapper.propTypes = {
    renderer: func.isRequired,
};
export default ResponsiveWrapper;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { useUIDSeed } from 'react-uid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import style from './EightUp.module.css';
import replaceSpecialChar from '../../../../../../helpers/replaceSpecialChar';
import cmsCssClassesToString from '../../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const EightUp = ({
    data,
}) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const seed = useUIDSeed();
    const defaultParams = 'auto=webp';
    useStyles(style);

    if (data.tiles?.reference?.length) {
        const {
            ga_constants = {},
            mobile_presentation_style = 'stacked',
            tile = [],
            mobile_view_all: mobileData = {},
        } = data.tiles.reference[0] || {};

        let primaryTile = tile;
        if (isMobile && mobileData.cta?.link?.href) {
            primaryTile = [...tile, mobileData];
        }

        return (
            <div className={`${style.wrapper} ${mobile_presentation_style === 'carousel' ? style.carouselWrapper : ''}`}>
                {
                    primaryTile.map(({ image = {}, cta = {}, css_classes = ['text2', 'u-margin-top-14', 'u-center'] }) => (

                        <LinkOrATag
                            isTrackEventEnabled
                            href={cta?.link.href}
                            trackingEventCategory={ga_constants?.tracking_event_category || ''}
                            trackingEventAction={ga_constants?.tracking_event_action || ''}
                            trackingEventLabel={cta?.tracking_event_label || ''}
                            className={style.imageWrapper}
                            indexKey={seed(image || cta || 'eightUp')}
                            noLinkOrTitle={!cta?.link?.title && !cta?.link?.href}
                        >
                            <img className={style.image} src={`${image?.url}?${defaultParams}`} alt={image?.description || image?.title} loading="lazy" />
                            {cta?.link?.title &&  (
                                <div className={style.productContent}>
                                    <p className={cmsCssClassesToString(css_classes.length > 0 ? css_classes : ['text2', 'u-margin-top-14', 'u-center'])}>{replaceSpecialChar(cta?.link.title, true)}</p>
                                </div>
                            )}
                        </LinkOrATag>
                    ),
                    )
                }
            </div>
        );
    }

    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Tiles/EightUp/EightUp.js',
        message: 'No data available to render component',
    });
};

EightUp.propTypes = {
    data: object.isRequired,
};

export default EightUp;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import Media from 'react-media';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import style from './SingleProduct.module.css';
import universalHandleClick from '../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../SubComponents/Message/MessageStandard'));

const SingleProduct = ({ data }) =>     {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Features/Standard/SingleProduct',
            message: 'No data available to render component',
        });
    }
    const productData = data?.product_feature?.reference?.[0] || [];
    useStyles(style);

    const defaultParams = 'auto=webp';
    const handleClick = universalHandleClick();
    const singleProductClasses = productData?.invert_layout ? style.rightSideProduct : style.leftSideProduct;
    const productDataLink = productData?.product?.linking;
    const renderBanner = (matches) => {
        const mobileView = matches.mobile;
        return (
            <div className={`${style.productContainer} ${!mobileView ? singleProductClasses : ''}`}>
                <div className={style.primaryImageContainer}>
                    <picture>
                        <source srcSet={`${productData?.primary_image?.url}?${defaultParams}`} />
                        <img src={`${productData?.primary_image?.url}?${defaultParams}`} alt={productData?.primary_image?.title} loading="lazy" width="600px" height="692px" />
                    </picture>

                </div>
                <div className={`${style.messageContainer} ${!productDataLink?.link?.href ? style.disableClick : ''}`}  onClick={() => handleClick(productDataLink)} role="presentation" data-testid="singleProduct">
                    {productData?.product?.title &&  !mobileView && <div className={style.heading}> {productData.product.title}</div>}
                    <picture>
                        <source srcSet={`${productData?.product?.image?.url}?${defaultParams}`} />
                        <img src={`${productData?.product?.image?.url}?${defaultParams}`} alt={productData?.product?.image?.title} loading="lazy" width="338" height="356px" />
                    </picture>
                    <MessageStandard
                        data={{
                            heading: mobileView ? productData?.product?.title : '',
                            sub_heading: productData?.product?.sub_title,
                            cta: {
                                link: {
                                    href: productData?.product?.linking.link.href,
                                    title: productData?.product?.linking.link.title,
                                },
                                presentation: 'text',
                                tracking_event_category: productData?.product?.linking.tracking_event_category,
                                tracking_event_action: productData?.product?.linking.tracking_event_action,
                                tracking_event_label: productData?.product?.linking.tracking_event_label,
                            },
                            has_background: false,
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <Media queries={{
                desktop: '(min-width:1024px)',
                tablet: '(min-width: 600.5px) and (max-width: 1023.5px)',
                mobile: '(max-width: 600px)',
            }}
            >
                {(matches) => renderBanner(matches)}
            </Media>
        </>
    );
};
SingleProduct.propTypes = {
    data: object.isRequired,
};
export default SingleProduct;

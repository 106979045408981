/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './ExtraShort.module.css';
import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const ExtraShortBanner = ({ data, salesforceResponse }) =>     {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Standard/ExtraShort/ExtraShort.js',
            message: 'No entries provided for ExtraShortBanner',
        });
    }
    useStyles(styles);
    const handleClick = universalHandleClick(salesforceResponse);
    let containerClass = '';
    switch (data.message.message_position.message_position) {
        case 'align-items: center; justify-content: center;':
            containerClass = `${styles.extraShortBanner} ${styles.extraShortBannerCenter}`;
            break;
        case 'align-items: flex-end; justify-content: center;':
            containerClass = `${styles.extraShortBanner} ${styles.extraShortBannerRight}`;
            break;
        default: containerClass = styles.extraShortBanner;
    }

    const backgrounds = {
        desktop: data.background_image?.desktop?.url,
        tablet: data.background_image?.tablet?.url, // can add fallbacks easily this way
        mobile: data.background_image?.mobile?.url,
    };

    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    const imageQuality = '?auto=webp';
    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgrounds[device(matches)]}${imageQuality})` }} className={containerClass}>
                <MessageStandard data={data?.message} />
                {backgrounds[device(matches)] && <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" />}
            </div>

        </>
    );
    const bannerLink = data?.message?.cta?.link;
    return (
        <div data-testid="extraShortBanner" role="presentation" className={bannerLink?.href && bannerLink?.title ? styles.bannerContainer : ''} onClick={() => handleClick(data.message.cta)}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};
ExtraShortBanner.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
ExtraShortBanner.defaultProps = {
    salesforceResponse: {},
};
export default ExtraShortBanner;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';
import mbpLogger from 'mbp-logger';

import loadable from '@loadable/component';

const FourCTAProduct = loadable(() => import(/* webpackChunkName: "FourCTAProduct" */ '../FourCTAProduct/FourCTAProduct'));
const FourProduct = loadable(() => import(/* webpackChunkName: "FourProduct" */ '../FourProduct/FourProduct'));

const Standard = ({
    data,
}) => {
    if (data?.length) {
        const fourProductData = data[0] || {};
        const gaConstants = fourProductData?.ga_constants || {};
        const primaryData = fourProductData?.primary_image || {};
        const imagePosition = fourProductData?.primary_image_position === 'row-reverse';
        return (
            <div data-testid="Standard">
                {fourProductData.variant_a?.length > 0 && <FourCTAProduct data={fourProductData.variant_a[0]} gaConstants={gaConstants} primaryData={primaryData} imagePosition={imagePosition} />}
                {fourProductData.variant_b?.length > 0 && <FourProduct data={fourProductData.variant_b[0]} gaConstants={gaConstants} primaryData={primaryData} imagePosition={imagePosition} />}
            </div>
        );
    }
    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Features/Standard/Standard.js',
        message: 'No data available to render component',
    });
};
Standard.propTypes = {
    data: array.isRequired,
};

export default Standard;

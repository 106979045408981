/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';

import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';

import Standard from './Standard/Standard';
import SingleProduct from './Standard/SingleProduct';

const Features = ({
    data,
}) => {
    const renderPanel = () => {
        const contentType = data?.product_feature?.reference?.[0]?.['_content_type_uid'];
        switch (contentType) {
            case 'feature_1up_product_four':
                return <ComponentErrorBoundary module="Features.Standard"><Standard data={data.product_feature.reference} /></ComponentErrorBoundary>;
            case 'feature_1up_product_one':
                return <ComponentErrorBoundary module="Features.SingleProduct"><SingleProduct data={data} /></ComponentErrorBoundary>;
            default: return <></>;
        }
    };
    return (
        <div data-testid="feature">
            {renderPanel()}
        </div>
    );
};

Features.propTypes = {
    data: array.isRequired,
};

export default Features;

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\n * Confidential and Proprietary.\n * Do not distribute without 1-800-Flowers.com, Inc. consent.\n * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.\n */\n\n:root {\n    --cta-btn-bg: #ffffff;\n    --cta-btn-color: #2f2f2f\n}\n\n.Tiles_container__lDTUf {\n    overflow: auto;\n}\n@media (max-width: 1400px){\n    .Tiles_headingContainer__W7Byp {\n        margin:0 10px;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/GraphqlComponents/UniversalTemplate/Components/Tiles/Tiles.module.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,qBAAqB;IACrB;AACJ;;AAEA;IACI,cAAc;AAClB;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["/*\n * Confidential and Proprietary.\n * Do not distribute without 1-800-Flowers.com, Inc. consent.\n * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.\n */\n\n:root {\n    --cta-btn-bg: #ffffff;\n    --cta-btn-color: #2f2f2f\n}\n\n.container {\n    overflow: auto;\n}\n@media (max-width: 1400px){\n    .headingContainer {\n        margin:0 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Tiles_container__lDTUf",
	"headingContainer": "Tiles_headingContainer__W7Byp"
};
module.exports = ___CSS_LOADER_EXPORT___;

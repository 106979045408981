/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const cmsCssClassesToString = (cssClasses) => {
    if (cssClasses === undefined || cssClasses === null || cssClasses.length === 0) {
        return '';
    }
    if (cssClasses.length > 1) {
        // take array converts to comma seperated string and joins them together with a space
        // i.e. ['header1','font-decorative'] -> 'header1,font-decorative' -> 'header1 font-decorative'
        return cssClasses.toString().split(',').join(' ');
    }
    // else if array has only one element
    // ['text1'] -> 'text1'
    return cssClasses?.toString();
};

export default cmsCssClassesToString;

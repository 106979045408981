/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';

import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

const FourUpVariantA = loadable(() => import(/* webpackChunkName: "FourUpStandardVariantA" */ './FourUpVariantA/FourUpVariantA'));
const FourUpVariantB = loadable(() => import(/* webpackChunkName: "FourUpStandardVariantB" */ './FourUpVariantB/FourUpVariantB'));
const FourUpVariantC = loadable(() => import(/* webpackChunkName: "FourUpStandardVariantC" */ './FourUpVariantC/FourUpVariantC'));
const FourUpVariantD = loadable(() => import(/* webpackChunkName: "FourUpStandardVariantD" */ './FourUpVariantD/FourUpVariantD'));
const FourUpReview = loadable(() => import(/* webpackChunkName: "FourUpStandardReview" */ './FourUpReview/FourUpReview'));

const FourUpStandard = ({
    data,
}) => {
    if (data?.length) {
        const fourUpData = data[0] || {};
        const gaConstants = fourUpData.ga_constants;
        return (
            <div data-testid="FourUpStandard">
                {fourUpData.variant_a?.length > 0 && <FourUpVariantA data={fourUpData.variant_a[0]} gaConstants={gaConstants} />}
                {fourUpData.variant_b?.length > 0 && <FourUpVariantB data={fourUpData.variant_b[0]} gaConstants={gaConstants} />}
                {fourUpData.variant_c?.length > 0 && <FourUpVariantC data={fourUpData.variant_c[0]} gaConstants={gaConstants} />}
                {fourUpData.variant_d?.length > 0 && <FourUpVariantD data={fourUpData.variant_d[0]} gaConstants={gaConstants} />}
                {fourUpData.review?.length > 0 && <FourUpReview data={fourUpData.review[0]} gaConstants={gaConstants} />}
            </div>
        );
    }
    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Tiles/FourUpStandard/FourUpStandard.js',
        message: 'No data available to render component',
    });
};

FourUpStandard.propTypes = {
    data: array.isRequired,
};

export default FourUpStandard;
